import React from 'react'
import image from '../images/about_us_page.png'
import Link from 'gatsby-link'

class Blog extends React.Component{
    render(){
        return(
            <section id="blog_page" className="hero blog-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">

                        </div>{/*STATIC PART END */}

                        <div className="column is-8 content-part is-right padding-0">
                            <div className="inner">
                                <h1 className="page-title">BLOG</h1>
                                <span className="zigzag"></span>
                                <div className="divider"></div>
                                <div className="single-row columns">
                                    <div className="column is-4 image">
                                        <Link to="/"><img src={image} alt="Blog" /></Link>
                                    </div>
                                    <div className="column is-8 content">
                                        <h2><Link to="/" className="title">DOO ĆATIĆ COMPANY PRIJEPOLJE - Dobitnik Gold sertifikata 
                                        ĆATIĆ COMPANY PRIJEPOLJE dobitnik je Gold sertifikata za odlično poslovanje u 
                                        2015. 2016. 2017.
                                    </Link> </h2>
                                        <Link to="/" className="button default">VIŠE</Link>
                                    </div>
                                </div>{/*SINGLE ROW END */}

                                <div className="single-row columns">
                                    <div className="column is-4 image">
                                        <Link to="/"><img src={image} alt="Blog" /></Link>
                                    </div>
                                    <div className="column is-8 content">
                                        <h2><Link to="/" className="title">DOO ĆATIĆ COMPANY PRIJEPOLJE - Dobitnik Gold sertifikata 
                                        ĆATIĆ COMPANY PRIJEPOLJE dobitnik je Gold sertifikata za odlično poslovanje u 
                                        2015. 2016. 2017.
                                    </Link> </h2>
                                        <Link to="/" className="button default">VIŠE</Link>
                                    </div>
                                </div>{/*SINGLE ROW END */}

                                <div className="single-row columns">
                                    <div className="column is-4 image">
                                        <Link to="/"><img src={image} alt="Blog" /></Link>
                                    </div>
                                    <div className="column is-8 content">
                                        <h2><Link to="/" className="title">DOO ĆATIĆ COMPANY PRIJEPOLJE - Dobitnik Gold sertifikata 
                                        ĆATIĆ COMPANY PRIJEPOLJE dobitnik je Gold sertifikata za odlično poslovanje u 
                                        2015. 2016. 2017.
                                    </Link> </h2>
                                        <Link to="/" className="button default">VIŠE</Link>
                                    </div>
                                </div>{/*SINGLE ROW END */}
                            </div>
                        </div>{/* PRODUCTS PART END */}

                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* BLOG PAGE SECTION END */
        );
    }
}

export default Blog