import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import Header from '../components/Header'
import Blog from '../components/Blog'
import Footer from '../components/Footer'

class BlogPage extends React.Component{
    render(){
        return(
            <Layout>
                <Helmet>
                <meta charset="utf-8"/>
                <title>WALTZ | Blog</title>
                <meta name="description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj."/>
                <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta itemprop="name" content="WALTZ | Blog"/>
                <meta itemprop="description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj."/>
                <meta itemprop="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:title" content="WALTZ | Blog"/>
                <meta name="og:description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj."/>
                <meta name="og:image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:url" content="https://waltz.rs/blog"/>
                <meta name="og:site_name" content="WALTZ | Blog"/>
                <meta name="og:locale" content="sr"/>
                <meta name="og:type" content="website"/>
                </Helmet>
              <Header />
              <Blog />
              <Footer />
            </Layout>
        );
    }
}
  export default BlogPage;
  